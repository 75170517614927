// assets
import { ChromeOutlined, QuestionOutlined, SettingOutlined } from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  SettingOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support =
   {
        id: 'support',
        title: 'Support',
        type: 'group',
        children: [
          {
            id: 'admin',
            title: 'User Settings',
            type: 'item',
            url: '/settings',
            icon: icons.SettingOutlined,
            breadcrumbs: false
          },
           {
            id: 'nps-settings',
            title: 'NPS Settings',
            type: 'item',
            url: '/nps-settings',
            icon: icons.SettingOutlined,
            breadcrumbs: false
          }
        ]
      }
    

export default support;
