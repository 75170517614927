import { useEffect, useState } from "react";

export const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);
 
  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};

export const arrayToCSV = (arr, delimiter = ",") =>
  arr
    .map((v) =>
      v
        .map((x) => (isNaN(x) ? `"${x.replace(/"/g, '""')}"` : x))
        .join(delimiter)
    )
    .join("\n");

export const CSVToArray = (data, delimiter = ",", omitFirstRow = false) => {
  if (data) {
    return data
      .slice(omitFirstRow ? data.indexOf("\n") + 1 : 0)
      .split("\n")
      .map((v) => v.split(delimiter));
  } else return [];
};

export const calculateTotal = (file, helper) => {
  const array = CSVToArray(file);
  let total = 0;
  array.map((arr) => {
    total += parseFloat(arr[1]);
  });
  helper(total);
  return total;
};
export const checkError = (address) => {
  if (/^0x[a-fA-F0-9]{40}$/g.test(address) === false) {
    return true;
  }
};

const checkDuplicates = (arr) => {
  let hash = {};
  const duplicates = [];
  arr.map((arr, i) => {
    if (!hash[arr.address]) {
      hash[arr.address] = arr.line;
    } else {
      duplicates.push({ line: arr.line, address: arr.address, type: "b" });
    }
    return;
  });

  return duplicates;
};
export const validateCSV = (csv, helper) => {
  let errors = [];
  let addresses = [];
  if (csv) {
    const array = CSVToArray(csv);
    array?.map((arr, i) => {
      addresses.push({ address: arr[0], line: i + 1 });
      if (checkError(arr[0])) {
        errors.push({ line: i + 1, address: arr[0], type: 'a' });
        return i + 1;
      }
    });
  }
  helper([...errors, ...checkDuplicates(addresses)]);
  return errors;
};

export const addSameAmount = (arr, amount, helper) => {
  if (amount && amount > 0) {
    const array = CSVToArray(arr);
    const newArr = array.map((arr, i) => {
      arr[1] = amount;

      return arr;
    });

    helper(arrayToCSV(newArr));
    return arrayToCSV(newArr);
  }
};
