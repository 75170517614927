import { useRoutes } from 'react-router-dom';

// project import
//import LoginRoutes from './LoginRoutes';
//import MainRoutes from './MainRoutes';
import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const MyMessages = Loadable(lazy(() => import('pages/components-overview/MyMessages')));
const AllMessages = Loadable(lazy(() => import('pages/components-overview/AllMessages')));
const Settings = Loadable(lazy(() => import('pages/components-overview/Settings')));
const NpsSettings = Loadable(lazy(() => import('pages/components-overview/NpsSettings')));
const Payments = Loadable(lazy(() => import('pages/components-overview/Payments')));
const CreateSurvey = Loadable(lazy(() => import('pages/components-overview/CreateSurvey')));

const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));

import useWebSocket from 'react-use-websocket';
import { checkValidTime } from 'api/index';
import SurveyQuestions from 'pages/components-overview/SurveyQuestions';
import PushSurvey from 'pages/components-overview/PushQuestions';
import Responses from 'pages/components-overview/Responses';
const token = JSON.parse(localStorage.getItem('eswc_token'));
const expired = localStorage.getItem('expired') ? JSON.parse(localStorage.getItem('expired')) : null;
//const socketUrl = `wss://cb-backend.ewsc.co.sz/agentws/?token=${token}`;
//const socketUrl = `wss://testbackend.ewsc.co.sz/agentws/?token=${token}`;
const socketUrl = `wss://robot.adroyte.net:3000/?token=${token}`;
export default function ThemeRoutes() {
  const { sendMessage, lastMessage } = useWebSocket(socketUrl, {
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (_closeEvent) => {
      if (!token || checkValidTime(expired) == false || expired == null) {
        return false;
      } else {
        return true;
      }
    },
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    //attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
    reconnectInterval: (attemptNumber) => Math.min(Math.pow(1.5, attemptNumber) * 1000, 10000),
    onMessage: (event) => {
      return; 
    }
  });
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <DashboardDefault />
        },
        {
          path: 'all-messages',
          element: <AllMessages />
        },

        {
          path: 'settings',
          element: <Settings />
        },
        {
          path: 'nps-settings',
          element: <NpsSettings />
         },
        {
          path: 'my-messages',
          element: <MyMessages lastMessage={lastMessage} sendMessage={sendMessage} />
        },
        {
          path: 'payments',
          element: <Payments />
        },
        {
          path: 'survey-questions',
          element: <SurveyQuestions />
        },
        { path: 'create-survey', element: <CreateSurvey /> },
        { path: 'push-survey', element: <PushSurvey /> },
        { path: 'survey-RESPONSE', element: <Responses /> }
      ]
    },
    {
      path: '/',
      element: <MinimalLayout />,
      children: [
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'edit-profile',
          element: <AuthRegister />
        }
      ]
    }
  ]);
}
