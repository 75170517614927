import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';

// chart options


// ==============================|| MONTHLY BAR CHART ||============================== //

const MonthlyPieChart = ({series,labels, colors}) => {
  const theme = useTheme();

 const barChartOptions = {
   chart: {
     width: 380,
     type: 'pie'
   },
   colors: colors?colors:['#007fbc', '#546E7A', '#E91E63', "#55f5f6"],
   labels: labels,
   responsive: [
     {
       breakpoint: 480,
       options: {
         chart: {
           width: 200
         },
         legend: {
           position: 'bottom'
         }
       }
     }
   ]
 };


  

  const [options, setOptions] = useState(barChartOptions);

  

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="pie" width={365} />
    </div>
  );
};

export default MonthlyPieChart;
