export default class Admin {
  constructor(client) {
    this.client = client;
  }

  createAdmin(payload) {
    return this.client.post('/agent/', payload);
  }

  updateAdmin(payload) {
    const email = payload.email;
    delete payload.email;
    //delete payload.password;
    return this.client.patch(`/agent/${email}`, payload);
  }

  deleteAdmin(email) {
    return this.client.delete(`/agent/${email}`);
  }

  loginAdmin(payload) {
    return this.client.post('/agent/login', payload);
  }

  getAllAdmins(page, pageSize) {
    return this.client.get(`/agent?page=${page}&pageSize=${pageSize}`);
  }

  getSingleAgent(email) {
    return this.client.get(`/agent/${email}`);
  }
}
