export default class Survey {
  constructor(client) {
    this.client = client;
  }

  createSurvey(body) {
    return this.client.post(`/survey`, body);
  }

  getAllSurvey(page, pageSize) {
    return this.client.get(`/survey?page=${page}&pageSize=${pageSize}`);
  }

  fetchOneSurvey(service_name) {
    return this.client.get(`/survey/${service_name}`);
  }
  deleteSurvey(service_name) {
    return this.client.delete(`/survey/${service_name}`);
  }

  editSurvey(body) {
    const service_name = body.service_name
     delete body.service_name;
    return this.client.put(`/survey/${service_name}`, body);
  }
}
