


export default class Messages {
  constructor(client) {
    this.client = client;
  }

  revertToBot(session_id) {
    return this.client.patch(`/message/drop-client-for-bot/${session_id}`);
  }

  getAllMessages() {
    return this.client.get('/message/unaccepted-client');
  }

  getAgentMessages(agent_id) {
    return this.client.get(`/message/accepted-client/${agent_id}`);
  }

  claimMessage(payload) {
    return this.client.patch(`/message/accept-client/${payload.session_id}`, { agent_id: payload.agent_id });
  }

  dropClient(payload) {
    return this.client.patch(`/message/drop-client/${payload.session_id}`, { agent_id: payload.agent_id });
  }

  getHistoricalData(session_id, pageSize = 10, page = 1) {
    return this.client.get(`/message/historical-message/${session_id}?pageSize=${pageSize}&page=${page}`);
  }
}
