export default class Settings {
  constructor(client) {
    this.client = client;
  }

  createSurvey(body) {
    return this.client.post(`/settings`, body);
  }

  getAllSettings(page, pageSize) {
    return this.client.get(`/settings`);
  }

  fetchOneSettings(service_name) {
    return this.client.get(`/settings/${service_name}`);
  }
  deletesettings(service_name) {
    return this.client.delete(`/settings/${service_name}`);
  }

  editSettings(body) {
    const service_name = body.key;
    delete body.key
    return this.client.patch(`/settings/${service_name}`, body);
  }
}
