import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  LoadingOutlined,
  MessageOutlined,
  OrderedListOutlined,
  MailOutlined,
  ChromeOutlined,
  SettingOutlined,
  InboxOutlined,
  ShareAltOutlined,
  EditOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';

 const user = JSON.parse(localStorage.getItem('user'));
const icons = {
  FontSizeOutlined,
  MailOutlined,
  ShareAltOutlined,
  EditOutlined,
  SettingOutlined,
  ChromeOutlined,
  InboxOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  AppstoreAddOutlined,
  MessageOutlined,
  OrderedListOutlined,
  QuestionCircleOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const surveys = {
  id: 'surveys',
  title: 'NPS',
  type: 'group',
  children: user?.role==="admin"? [
    {
      id: 'create-survey',
      title: 'Create NPS',
      type: 'item',
      url: '/create-survey',
      icon: icons.EditOutlined
    },
    {
      id: 'survey-questions',
      title: 'NPS Questions',
      type: 'item',
      url: '/survey-questions',
      icon: icons.QuestionCircleOutlined
    },
    {
      id: 'push-survey',
      title: 'Push NPS',
      type: 'item',
      url: '/push-survey',
      icon: icons.ShareAltOutlined
    },
    {
      id: 'survey-response',
      title: 'NPS Responses',
      type: 'item',
      url: '/survey-response',
      icon: icons.InboxOutlined
    }
  ]:[
      {
      id: 'create-survey',
      title: 'Create NPS',
      type: 'item',
      url: '/create-survey',
      icon: icons.EditOutlined
    },
    {
      id: 'survey-questions',
      title: 'NPS Questions',
      type: 'item',
      url: '/survey-questions',
      icon: icons.QuestionCircleOutlined
    },
    {
      id: 'push-survey',
      title: 'Push NPS',
      type: 'item',
      url: '/push-survey',
      icon: icons.ShareAltOutlined
    },
    
  ]
};

export default surveys;
