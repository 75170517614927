// material-ui
indexedDB;
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
// project import
import MainCard from 'components/MainCard';

// assets

// ==============================|| DRAWER CONTENT - NAVIGATION CARD ||============================== //

const NavCard = () => {
  const navigate = useNavigate();


  const handleLogout = async () => {
    localStorage.setItem('user', null);
    localStorage.setItem('eswc_token', null);
     localStorage.setItem('expired', null);
    navigate('/login');
  };

 return ( <MainCard sx={{ bgcolor: 'grey.50', m: 1 }}>
    <div style={{cursor:"pointer"}} onClick={handleLogout}>
      <LogoutOutlined />
      <span style={{ marginLeft: '10px' }}>Logout</span>
    </div>
  </MainCard>
)};

export default NavCard;
