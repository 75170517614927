export default class Payments {
  constructor(client) {
    this.client = client;
  }

  getAllTransactios(page,pageSize) {
    return this.client.get(`/payment/all-payment-record?page=${page}&pageSize=${pageSize}`);
  }

  fetchByTransactionId(trxid) {
    return this.client.get(`/payment/fetch-payment-by-trxid/${trxid}`);
  }
  fetchBySessionId(session_id) {
    return this.client.get(`/payment/fetch-payment-by-sessionid/${session_id}`);
  }

  fetchByAccountNo(account_no) {
    return this.client.get(`/payment/fetch-payment-by-account_to_recieve/${account_no}`);
  }
}
