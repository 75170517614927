// assets
import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  LoadingOutlined,
  MessageOutlined,
  OrderedListOutlined,
  MailOutlined,
  ChromeOutlined,
  SettingOutlined,
} from '@ant-design/icons';


const icons ={
  FontSizeOutlined,
    MailOutlined,
    SettingOutlined,
    ChromeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    AppstoreAddOutlined,
    MessageOutlined,
    OrderedListOutlined
}

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: 'utilities',
  title: 'Chatbot',
  type: 'group',
  children: [
    {
      id: 'util-typography',
      title: 'All Messages',
      type: 'item',
      url: '/all-messages',
      icon: icons.MessageOutlined
    },
    {
      id: 'util-color',
      title: 'My Messages',
      type: 'item',
      url: '/my-messages',
      icon: icons.MailOutlined
    },
    {
      id: 'payments',
      title: 'Payments',
      type: 'item',
      url: '/payments',
      icon: icons.ChromeOutlined
    }
  ]
};

      

export default utilities;
