import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination } from 'react-table';
import { Table, Row, Col, Button, Input } from 'reactstrap';
import { Filter, DefaultColumnFilter } from './filters';
import { Select } from 'antd';
import moment from "moment"
import convertArrayToCSV from 'convert-array-to-csv';
 const user = JSON.parse(localStorage.getItem('user'));
const MyFilter = ({ search, searchValue }) => {
  const [value, setValue] = React.useState(searchValue);

  return (
    <Col style={{ display: 'flex' }}>
      <Col sm={4}>
        <div className="search-box me-2 mb-2 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Global Search
              </span>
              <input
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={'Search All Records'}
                value={value || ''}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      <Col sm={4}>
        <Button onClick={() => search(value)}>Search</Button>
        <Button style={{ marginLeft: '5px' }} onClick={() => search('')}>
          Reload
        </Button>
      </Col>
    </Col>
  );
};
// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Col sm={4}>
      <div className="search-box me-2 mb-2 d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              placeholder={` search ${count} records`}
              value={value || ''}
            />
          </label>
          <i className="bx bx-search-alt search-icon"></i>
        </div>
      </div>
    </Col>
  );
}

const Download = ({ data }) => {
  if (data.length === 0) {
    console.error('Data array is empty.');
    return;
  }

  const clean = data?.map(
    ({
      id,
      question_id,
      pushed_id,
      response,
      text,
      channel,
      created_at,
      created_by,
      result,
      service_name,
      answered_by,
      pushed_by,
      pushed_to,
      all_status
    }) => {
      return {
        id,
        service_name,
        text,
        channel,
        response,
        result,
        pushed_by,
        question_id,
        answered_by,
        pushed_id,
        created_at
      };
    }
  );

  const csv = convertArrayToCSV(clean);
  const blob = new Blob([csv], {
    type: 'text/csv;charset=utf-8'
  });

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob);

  return (
    <Button style={{ width: '120px' }} tag="a" color="primary" download="eswc_csv" target="_blank" rel="noreferrer" href={url}>
      Download
    </Button>
  );
};

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isNoPagination,
  isAdminList,
  isNpsList,
  isPushList,
  isSurveyList,
  isPaymentList,
  isQuestionList,
  isResponseList,
  handleSearch,
  handleSearchChange,
  handleParamChange,
  optionList,
  handleQuestionChange,
  handleDateChange,
  startDate,
  endDate,
  searchParam,
  setShowDetailsModal,

  handleAddWhiteList = () => {},

  customPageSize,

  customSetPageSize,
  customPageCount,
  customPrevPage,
  customCanPrevPage,
  customNextPage,
  setFilters,
  searchValue,
  customCanNextPage,
  controlledPageIndex,
  setOpen = () => {},
  className
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize
        // sortBy: [
        //   {
        //     id: isAdminList ? 'start_time' : null
        //   }
        // ]
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const onChangeInSelect = (event) => {
    customSetPageSize ? customSetPageSize(Number(event.target.value)) : setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={2}>
          <select
            className="form-select"
            value={customPageSize ? customPageSize : pageSize}
            style={{ width: 130 }}
            onChange={onChangeInSelect}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter ? (
          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
        ) : (
          <MyFilter search={setFilters} searchValue={searchValue} />
        )}

        {isAdminList && (
          <Col sm="6">
            <div className="text-sm-end">
              <Button type="button" color="primary" className="btn mb-2 me-2" onClick={handleAddWhiteList}>
                <i className="mdi mdi-plus-circle-outline me-1" />
                Add New Agent
              </Button>
            </div>
          </Col>
        )}

        {isNpsList && (
          <Col>
            <div className="text-sm-end">
              <Button type="button" color="primary" className="btn mb-2 me-2" onClick={handleAddWhiteList}>
                <i className="mdi mdi-plus-circle-outline me-1" />
                Add Settings
              </Button>
            </div>
          </Col>
        )}
        {isSurveyList && user.role === 'admin' && (
          <Col sm="6">
            <div className="text-sm-end">
              <Button type="button" color="primary" className="btn mb-2 me-2" onClick={handleAddWhiteList}>
                <i className="mdi mdi-plus-circle-outline me-1" />
                Add New Survey
              </Button>
            </div>
          </Col>
        )}
        {(isPushList || isResponseList) && (
          <>
            <Col md="3" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
              <Select style={{ width: '100%' }} placeholder="Filter by service" onChange={handleQuestionChange} options={optionList} />
            </Col>
            {isPushList && (
              <Col md="3">
                <div className="text-sm-end">
                  <Button type="button" color="primary" className="btn mb-2 me-2" onClick={handleAddWhiteList}>
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Push New
                  </Button>
                </div>
              </Col>
            )}
          </>
        )}

        {(isQuestionList || isResponseList) && (
          <>
            <Col md="3">
              {isResponseList ? (
                <Button type="button" color="primary" className="btn mb-2 me-2" onClick={handleAddWhiteList}>
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Advanced Filter
                </Button>
              ) : (
                <Select
                  mode="tags"
                  style={{ width: '80%' }}
                  placeholder="Pick a service"
                  onChange={handleQuestionChange}
                  options={optionList}
                />
              )}
            </Col>

            {isQuestionList && user.role === 'admin' && (
              <Col md="3" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <Button type="button" color="primary" className="btn mb-2 me-2" onClick={handleAddWhiteList}>
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Add Question
                </Button>
              </Col>
            )}
          </>
        )}

        {isPaymentList && (
          <Col sm="6">
            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }} className="text-sm-end">
              <Input
                style={{ width: '200px' }}
                type="select"
                name="trx-opt"
                id="role"
                value={searchParam}
                onChange={(e) => handleParamChange(e)}
                placeholder="parameter"
                className="form-control"
              >
                <option value="">Select Parameter</option>;
                {['Transaction id', 'Account Credited', 'Session id'].map((o) => {
                  return (
                    <option value={o} key={o}>
                      {o}
                    </option>
                  );
                })}
              </Input>
              <Input
                placeholder={searchParam ? `Enter  ${searchParam} ` : 'Pick an option'}
                style={{ width: '200px' }}
                onChange={(e) => handleSearchChange(e)}
                type="text"
              ></Input>
              <Button onClick={() => handleSearch(searchParam)}>Done</Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr
                    onClick={() => {
                      const newRow = row.original;
                      const created_at = new Date(newRow.created_at).toLocaleString();
                      const updated_at = new Date(newRow.update_date).toLocaleString();
                      delete newRow.update_date;
                      delete newRow.created_at;
                      newRow.created_at = created_at;
                      newRow.update_date = updated_at;
                      setShowDetailsModal(newRow);
                      setOpen(true);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
      {!isNoPagination && (
        <Row className="justify-content-md-end justify-content-center align-items-center">
          {isResponseList && <Download data={data} />}
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button color="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </Button>
              <Button
                color="primary"
                onClick={customPrevPage ? customPrevPage : previousPage}
                disabled={customCanPrevPage ? customCanPrevPage.value : !canPreviousPage}
              >
                {'<'}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{' '}
            <strong>
              {controlledPageIndex ? controlledPageIndex : pageIndex + 1} of {customPageCount ? customPageCount : pageOptions.length}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={customPageCount ? customPageCount : pageOptions.length}
              defaultValue={controlledPageIndex ? controlledPageIndex : pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={customNextPage ? customNextPage : nextPage}
                disabled={customCanNextPage ? !customCanNextPage : !canNextPage}
              >
                {'>'}
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  gotoPage(pageCount - 1);
                }}
                disabled={!canNextPage}
              >
                {'>>'}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
