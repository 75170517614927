export default class Analytics {
  constructor(client) {
    this.client = client;
  }

  getDailyClicks() {
    return this.client.get('/statistics/service-click-daily');
  }

  getMonthlyClicks() {
    return this.client.get(`/statistics/service-click-monthly`);
  }
  getWeeklyClicks() {
    return this.client.get(`statistics/service-click-weekly`);
  }

  getYearlyClicks() {
    return this.client.get(`statistics/service-click-yearly`);
  }
  getTemplates(){
    return this.client.get(`/template`)
  }
}

