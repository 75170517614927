import { Button, Modal } from "antd";
import { useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { createTheme } from "@uiw/codemirror-themes";
import { langs } from "@uiw/codemirror-extensions-langs";
import { classname } from "@uiw/codemirror-extensions-classname";
export const CustomModal = ({ isModalOpen, setIsModalOpen }) => {
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  

 
  const myTheme = createTheme({
    dark: "light",
    settings: {
      background: "#ffffff",
      foreground: "#3F3F46",
      border: "1px solid #bdb2f !important",
      caret: "red",
      fontFamily: "Inter",
      selection: "#ffffff",
      selectionMatch: "#ffffff",
      gutterBackground: "#f4f3ff",
      gutterForeground: "#4D4D4C",
      gutterBorder: "#bdb2fc",
      gutterBorderRadius: "9px",
      gutterActiveForeground: "",
      lineHighlight: "#f4f3ff",
    },
  });

  return (
    <>
      <Modal
      zIndex={1200}
        title="Sample Csv"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          style={{
            border: "1px solid #bdb2fc",
            borderRadius: "9px",
            overflow: "hidden",
            fontSize: 10,
          }}
        >
          <CodeMirror
            value={
              "olalekan@gmail.com\n john@gmail.com\ncustomer2@gmail.com\n dlamini@yahoo.com\njimihendrixx@yahoo.co.sz"
            }
            theme={myTheme}
            height="180px"
            onChange={() => console.log("hi")}
            extensions={[
              langs.mathematica(),
         
           
            ]}
          />
        </div>
      </Modal>
    </>
  );
};
