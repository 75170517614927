// project import
//import pages from './pages';
import dashboard from './dashboard';
import utilities from './utilities';
import support from './support';
import survey from './surveys'

// ==============================|| MENU ITEMS ||============================== //
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
const menuItems = {
  items: user?.role == 'agent' ? [dashboard, utilities, survey] : [dashboard, utilities, survey, support]
};

export default menuItems;
